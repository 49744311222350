@import "../../styles//Variable.scss";

.footer {
  background-color: $secondary;
  margin-top: 50px;
  color: $white;
  padding: 40px 0px;
  .logo {
    width: 300px;
    margin-bottom: 30px;
  }
  .header-bottom {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.7);
  }
  .social {
    display: flex;
    .icon-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: $white, $alpha: 0.2);
      padding: 15px;
      border-radius: 50%;
      svg {
        fill: $white;
      }
    }
  }
  .op-6 {
    a {
      color: #fff;
    }
  }

    .footer-bottom {
      padding: 0px 15px;
      position: relative;
      p {
        color: #fff;
        margin: 0;
        padding: 15px 0px;
        font-size: 14px;
      }
  
      .footer-sub-center {
        text-align: center;
      }
  
      .footer-sub-right {
        position: absolute;
        top: 0;
        right: 15px;
  
        @media only screen and (max-width: 991px) {
          position: static;
          text-align: center;
        }
      }
  
      @media only screen and (max-width: 991px) {
        padding: 15px 15px;
  
        p {
          padding: 0px 0px;
        }
  
        .footer-sub-right {
          position: static;
          text-align: center;
        }
      }
    }

}

.payment-option-img {
  width: 100px;
  object-fit: contain;
  object-position: center;
}
