@import "../../../styles/Variable.scss";

.sidebar {
  background-color: $white;
  width: 300px;
  padding: 40px 0px;
  height: fit-content;
  min-height: 100vh;
  position: fixed;
  height: calc(100vh - 250px);
  overflow-y: auto;
  top: 0;
  left: 0;
  background: #ffffff;
  text-align: left;
  .logo {
    padding: 20px 0px;
    margin: 20px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);

    img {
      width: 100%;
    }
  }
  ul {
    padding: 0px;
    li {
      list-style: none;

      a {
        padding: 20px;
        color: rgba($color: #000000, $alpha: 0.2);
        display: block;
        border-left: 4px solid transparent;
      }

      .unique-link {
        padding: 20px;
        color: rgba($color: #000000, $alpha: 0.2);
        display: block;
        border-left: 4px solid transparent;
      }

      svg {
        fill: rgba($color: #000000, $alpha: 0.2);
        margin-right: 10px;
        height: 20px;
      }
      &:hover,
      &.active {
        background-color: rgba($color: $primary, $alpha: 0.2);
        // border-radius: 0px 10px 10px 0px;
        a {
          border-left: 4px solid $primary;
          color: $primary;
        }
        svg {
          fill: $primary;
        }
        .new-req {
          color: $black;
        }
      }
    }
  }
}
