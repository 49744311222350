$black: #000;
$primary: #ff9494;
$secondary: #2ebced;
$white: #fff;
$grey: #8a8a9b;
$purple: #bec0ff;
$danger: #ff2525;

%list-spacing {
    list-style: none;
    margin: 0;
    padding: 0;
}