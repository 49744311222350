@import "./Variable.scss";

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-Bold.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-Black.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-Heavy.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-ExtraBold.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-Light.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-Medium.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-Regular.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-SemiBold.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-Thin.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/font/gilroy/Gilroy-UltraLight.woff2") format("woff2"),
    url("../assets/font/gilroy/Gilroy-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Gilroy";
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

h1 {
  font-size: 40px;

  @media screen and (max-width: 767px) {
    font-size: 28px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 32px;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 36px;
  }
}

h2 {
  font-size: 32px;

  @media screen and (max-width: 767px) {
    font-size: 20px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 28px;
  }
}

h3 {
  font-size: 28px;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 20px;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 24px;
  }
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

p {
  font-size: 16px;
}

a {
  cursor: pointer;
  text-decoration: none !important;
}

body {
  background-image: url("../assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

//opacity
.op-6 {
  opacity: 0.6;
}

//font-weight
.fw-6 {
  font-weight: 600;
}

.fw-8 {
  font-weight: 800;
}

//font-size
.font50 {
  font-size: 50px;
}

.font30 {
  font-size: 30px;
}

.font12 {
  font-size: 12px;
}

//font-color
.color-grey {
  color: $grey;
}

.color-theme {
  color: $primary;
}

.color-secondary {
  color: $secondary !important;
}

.color-purple {
  color: $purple;
}

.color-danger {
  color: $danger;
}

input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

//space
.space-lg {
  letter-spacing: 7.2px;
}

.space {
  letter-spacing: 3.4px;
}

.space-sm {
  letter-spacing: 2.6px;
}

.theme-button {
  border: 1px solid transparent;
  // background-color: transparent;
  padding: 11px 30px;
  font-size: 14px;
  border-radius: 10px;
  margin-right: 5px;
  white-space: nowrap;
  border-radius: 50px;
  letter-spacing: 1.1px;

  img {
    margin-right: 5px;
    width: 20px;
    min-width: 20px;
  }

  i {
    margin-right: 5px;
    font-size: 20px;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.primary-btn {
    border-color: $primary;
    background-color: $primary;
    color: $white;
  }

  &.primary-btn-outline {
    border-color: $primary;
    background-color: $white;
    color: $primary;
  }

  &.black-btn-outline {
    border-color: $black;
    background-color: transparent;
    color: $black;
  }

  &.black-btn {
    border-color: transparent;
    background-color: rgba($color: $black, $alpha: 0.5);
    color: $white;
  }

  &.round-btn {
    height: 60px;
    width: 60px;
    padding: 10px;
    border-color: transparent;
    background-color: rgba($color: $primary, $alpha: 0.2);
    color: $white;
    position: relative;

    .notification-show {
      width: 15px;
      min-width: 15px;
      height: 15px;
      min-height: 15px;
      border-radius: 100px;
      background-color: $danger;
      position: absolute;
      top: 4px;
      right: 2px;
      border: 1px solid $white;
    }
  }

  &.trans-btn {
    border-color: transparent;
    background-color: transparent;
    border: none;
  }

  &.btn-lg {
    width: 250px;
    max-width: 250px;
  }
}

// form input
.form-group {
  margin-bottom: 15px;

  label {
    color: $black;
    margin: 0px 0px 6px;
    display: block;
    //   font-weight: $regular;
    font-size: 18px;

    @media screen and (max-width: 1366px) and (min-width: 768px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  .form-select {
    font-size: 18px;
    background-color: rgba($color: $secondary, $alpha: 0.1);
    border-radius: 50px;
    padding: 15px 34px 15px 30px;
    outline: none;
    box-shadow: none;
    border: none;

    // width: fit-content;
    &.theme-select {
      background-color: rgba($color: $primary, $alpha: 0.1);
      color: $primary;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba($color: $black, $alpha: 0.3);
      //   opacity: 0.3;
    }
  }

  .form-control {
    font-size: 18px;
    background-color: rgba($color: $secondary, $alpha: 0.1);
    border-radius: 50px;
    padding: 15px 15px 15px 30px;
    outline: none;
    box-shadow: none;
    border: none;

    &::placeholder {
      color: rgba($color: $black, $alpha: 0.3);
      //   opacity: 0.3;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      background-color: rgba($color: $secondary, $alpha: 0.1);
    }

    @media screen and (max-width: 1366px) and (min-width: 768px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  //search-bar
  .custom-search {
    background: $white !important;
    border: 1px solid $primary !important;
    border-radius: 40px !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &::placeholder {
      color: rgba($color: $primary, $alpha: 0.3);
    }

    svg {
      fill: $primary;
    }
  }

  .inline-label-input {
    display: flex;
    align-items: center;

    label {
      margin-right: 10px;
      white-space: nowrap;
    }
  }

  .form-select {
    background-size: 16px 16px;
    outline: none;
    box-shadow: none;
  }

  textarea {
    resize: none;
  }

  //right-icon-input
  .right-icon-input {
    position: relative;

    .form-control {
      padding-right: 40px;
    }

    span {
      position: absolute;
      top: 18px;
      right: 10px;
      z-index: 2;
      // ion-icon {
      //   color: $secondary;
      //   font-size: 18px;
      //   @media screen and (max-width: 1366px) and (min-width: 768px) {
      //     font-size: 18px;
      //   }
      //   @media only screen and (max-width: 767px) {
      //     font-size: 14px;
      //   }
      // }

      // ion-img {
      //   width: 18px;
      //   @media screen and (max-width: 1366px) and (min-width: 768px) {
      //     width: 14px;
      //   }
      //   @media only screen and (max-width: 767px) {
      //     width: 12px;
      //   }
      // }

      svg {
        height: 20px;

        @media screen and (max-width: 1366px) and (min-width: 768px) {
          height: 18px;
        }

        @media only screen and (max-width: 767px) {
          height: 12px;
        }
      }
    }
  }

  //left-icon-input
  .left-icon-input {
    position: relative;

    .form-control {
      padding-left: 65px;
    }

    span {
      position: absolute;
      top: 16px;
      left: 30px;
      z-index: 2;

      i {
        //   color: $secondary;
        font-size: 18px;

        @media screen and (max-width: 1366px) and (min-width: 768px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      img,
      svg {
        width: 18px;

        @media screen and (max-width: 1366px) and (min-width: 768px) {
          width: 14px;
        }

        @media only screen and (max-width: 767px) {
          width: 12px;
        }
      }
    }
  }

  //double-icon-input
  .double-icon-input {
    position: relative;

    .form-control {
      padding-left: 65px;
      padding-right: 65px;
    }

    span {
      position: absolute;
      z-index: 2;

      i {
        color: $secondary;
        font-size: 18px;

        @media screen and (max-width: 1366px) and (min-width: 768px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      img {
        width: 18px;

        @media screen and (max-width: 1366px) and (min-width: 768px) {
          width: 14px;
        }

        @media only screen and (max-width: 767px) {
          width: 12px;
        }
      }

      &.left-cion {
        top: 13px;
        left: 30px;
      }

      &.right-icon {
        top: 6px;
        right: -21px;
      }
    }
  }

  //image-input
  .image-input {
    &.left-image-input {
      position: relative;

      .form-control {
        padding-left: 65px;
      }

      .circle-img {
        position: absolute;
        top: 6px;
        left: 10px;
        border: 1px solid $secondary;
        border-radius: 100%;
        margin-right: 0px;
      }
    }
  }
}

//custom-checkbox

.outer-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid black;
    border-radius: 50%;
    margin-top: 6px;
  }

  // &:hover input ~ .checkmark {
  //   background-color: #ccc;
  // }

  input:checked~.checkmark {
    border: 2px solid $secondary;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $secondary;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.otp {
  input {
    height: 50px;
    width: 50px !important;
    border-radius: 50%;
    border: none;
    max-width: 50px;
    margin-right: 10px;
    background-color: rgba($color: $secondary, $alpha: 0.2);

    &:focus-visible,
    :focus {
      border: none;
    }
  }
}

.white-card {
  background: $white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.10);
  border-radius: 20px;
  padding: 15px;

  p {
    word-break: break-word;
  }

  &.card-sm {
    width: 400px;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      width: auto;
    }
  }

  .card-image-set {
    height: 200px;
    object-fit: cover;
    overflow: hidden;
  }

  &.upload-art-card {
    background: #fff;
    border-radius: 20px;
    padding: 15px;
    box-shadow: 0px 2px 5px 0px #bfd4d9;
    animation: hello-dialog .4s ease-in-out;
    transition: all .4s ease-in-out;

    // &.hello-dialog { 
    //   animation: hello-dialog .25s ease-in-out; 
    // }
    .card-image-set {
      height: 200px;
      object-fit: contain;
      overflow: hidden;
      border: 1px solid #eee;
      border-radius: 15px 15px;
      background-position: 0px 0px, 10px 10px;
      background-size: 20px 20px;
      background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
    }
  }

  .img-name {
    margin-bottom: 10px;
    text-align: center;

    p {
      color: #000;
      margin: 0px;
      font-size: 14px;
      word-break: break-all;
    }
  }

}

.upload-area {
  border: 2px dashed #0b0c0e9c;
  border-radius: 20px;
  padding: 20px;
  position: relative;

  .file-op {
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .upload {
    height: 300px;
    width: auto;

    @media screen and (max-width: 767px) {
      height: 100px;
    }
  }
}

.bd-bottom {
  border-bottom: 2px solid rgba($color: #000000, $alpha: 0.1);
  margin: 0px 80px;
  padding-bottom: 10px;
}

.bd-top {
  border-top: 2px solid rgba($color: #000000, $alpha: 0.2);

  // padding-top: 10px;
}

.social-icons {
  display: flex;

  .icon-inner {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: $secondary;
    }

    &:hover {
      background-color: $secondary;
      border-radius: 50%;

      svg {
        fill: $white;
      }
    }
  }
}

.react-date-picker__wrapper {
  border: none !important;
}

.fix-img {
  height: 150px;
  width: 150px;
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}

.fix-img-sm {
  height: 50px;
  width: 50px;
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
  object-position: center;
}

.border-theme {
  border: 1px solid $secondary;
}

.border-dark {
  border: 1px solid $black;
}

.bd-50 {
  border-radius: 50%;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close-btn {
  position: relative;

  .close-inner {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.modal-content {
  border-radius: 33px;
}

.btn-close {
  background-color: transparent;
  background: none;
  opacity: 1;
}

.inner-content {
  width: 170px;
  min-width: 170px;
}

.custom-table {
  // border-collapse: unset;
  text-align: center;

  &.table {
    thead {
      tr {
        td {
          vertical-align: middle;
          color: $white;

          &:nth-child(1) {
            border-left: 0px !important;
          }

          &:nth-last-child(1) {
            border-right: 0px !important;
          }
        }
      }

      tr {
        td {
          // vertical-align: middle;
          background-color: $secondary;
          color: $white;
          font-weight: 500;
          padding: 20px;

          span {
            display: block;
          }

          &.bd-top-radius-left {
            border-top-left-radius: 10px;
          }

          &.bd-top-radius-right {
            border-top-right-radius: 10px;
          }
        }
      }
    }

    tbody {
      //border-collapse: collapse !important;
      border-top: 0px;

      tr {

        // td {
        //   &:first-child {
        //     font-weight: 600;
        //   }
        // }
        &:nth-child(odd) {
          td {
            background-color: rgba($color: $secondary, $alpha: 0.2);
          }
        }

        td {
          padding: 10px 20px;
          // text-align: center;
          vertical-align: middle;
          position: relative;

          // img {
          //   height: 30px;
          //   width: 30px;
          //   border: 1px solid;
          // }
          span {
            display: block;
            //text-decoration: line-through;
          }

          &.bd-bottom-radius-left {
            border-bottom-left-radius: 10px;
          }

          &.bd-bottom-radius-right {
            border-bottom-right-radius: 10px;
          }

          .dropdown-position-set {
            position: absolute;
            right: 15px;
            top: 50px;
            z-index: 2;

            ul {
              margin-bottom: 0;
            }
          }
        }

        &:last-child {
          td {
            .dropdown-position-set {
              right: 60px;
              top: -50px;
            }
          }
        }
      }
    }
  }
}

.nav {
  &.order-nav {
    justify-content: center;
    margin-bottom: 20px;

    .nav-item {
      a {
        display: inline-block;
        border-color: $primary;
        background-color: $white;
        color: $primary;
        padding: 10px 20px;
        border-radius: 50px;
        border: 1px solid $primary;
        margin-right: 10px;

        &:hover,
        &.active {
          border-color: $primary;
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}

.custom-progress {
  position: relative;

  ul {
    list-style: none;
    padding: 0px;
    display: flex;
    border-top: 5px solid rgba($color: $secondary, $alpha: 0.2);
    margin: 30px 60px;

    // &:before{
    //   content: "";
    //   position: absolute;
    //   top: -8px;
    // left: 41px;
    //   width: 20px;
    //   height: 20px;
    //   background-color: $secondary;
    //   border-radius: 100%;
    //   border: 1px solid $secondary;
    //   z-index: 2;
    // }
    // &:after{
    //   content: "";
    //   position: absolute;
    //   top: -8px;
    // right: 41px;
    //   width: 20px;
    //   height: 20px;
    //   background-color: $white;
    //   border-radius: 100%;
    //   border: 1px solid $secondary;
    //   z-index: 2;
    // }
    &.onboardBorder {
      border-top: 5px solid #f0a50038;

      li {
        &::before {
          border: 1px solid #F0A500;

        }
      }
    }


    &.active {
      &::after {
        background-color: $secondary;
      }
    }

    &.activeOnboard {
      &::after {
        background-color: #F0A500;
      }
    }

    li {
      position: relative;
      width: 34%;
      color: rgba($color: #000000, $alpha: 0.4);
      font-weight: 600;
      padding: 20px 0px;
      text-align: center;

      span {
        position: absolute;
        top: -5px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: transparent;
      }

      // &:last-child {
      //   span {
      //     position: absolute;
      //     top: -5px;
      //     left: 0;
      //     width: 100%;
      //     height: 5px;
      //     background-color: $white;
      //   }
      // }
      &:after {
        content: "";
        position: absolute;
        top: -13px;
        right: 0;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border-radius: 100%;
        z-index: 3;
        left: 0;
        margin: 0 auto;
      }

      &:before {
        content: "";
        position: absolute;
        top: -13px;
        right: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 100%;
        border: 1px solid #2ebced;
        z-index: 2;
        margin: 0 auto;
      }

      &.active {
        span {
          background-color: $secondary;
        }

        &::after {
          background-color: $secondary;
        }

        color: $secondary;
      }

      &.activeOnboard {
        span {
          background-color: #F0A500;
          ;
        }

        &::after {
          background-color: #F0A500;
        }

        color: #F0A500;
      }
    }
  }
}

.custom-chk {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-chk input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white;
  border: 2px solid rgba($color: $black, $alpha: 0.5);
  border-radius: 6px;
}

/* When the checkbox is checked, add a blue background */
.custom-chk input:checked~.checkmark {
  border: 2px solid $black;
  border-radius: 6px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-chk input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-chk .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

//main
.main {
  width: 100%;
  height: 100%;
  // display: flex;
  position: relative;

  .main-box {
    width: calc(100% - 300px);
    margin-left: 300px;
  }

  .main-wrapper {
    min-height: calc(100vh - 540px)
  }
}

.admin-nav {
  .nav-item {
    padding: 20px 30px;

    &.active {
      border-bottom: 2px solid $black;

      a {
        color: $black;
      }
    }

    a {
      color: rgba($color: $black, $alpha: 0.5);
      font-weight: 500;
    }
  }
}

.status {
  height: 20px !important;
  width: 20px !important;
  object-fit: contain;
  object-position: center;
}

.error {
  color: $danger;
  padding-left: 25px;
  text-align: left;
  font-size: 12px !important;

  i {
    color: $danger;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
}

.modal-custom-show {
  display: block;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }

  .modal-dialog {
    z-index: 1;
  }
}

.input-file-custom {
  position: relative;

  .inner {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}

.point {
  cursor: pointer;
}

.infinite-custom-scroll {
  overflow: visible !important;

  .jobs-list-scroll {
    max-height: calc(100vh - 520px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .table-responsive {
    height: 500px;

    .table {
      thead {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .popover-table {
        position: relative;

        svg {
          cursor: pointer;
        }

        .popover-list {
          position: absolute;
          @extend %list-spacing;
          background-color: $white;
          border-radius: 10px;
          z-index: 1;
          top: 50px;
          right: 30px;
          width: 220px;
          border: 1px solid;

          li {
            border-bottom: 1px solid $black;
            padding: 5px 10px;
            cursor: pointer;

            &:last-child {
              border: none;
            }
          }
        }
      }
    }
  }
}

// .shipping-loader {
//   width: 500px;
// }

.spinner-border {
  height: 20px;
  width: 20px;
}

.default-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid;

  &.font-lg {
    font-size: 60px;
  }

  &.font-sm {
    font-size: 20px;
    height: 50px;
    width: 50px;
  }
}

.a-tablecell {
  padding: 10px 20px;
  display: block;
  color: $black;
  text-decoration: none;
}

.custom-dropdown {
  padding: 0px 20px;
  border: 1px solid $primary;
  border-radius: 20px;
  list-style: none;
  background-color: $white;
}

.edit-set {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin: 0 auto;

  .input-set-image {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    object-fit: cover;
    overflow: hidden;
    object-position: center;
    border: 1px solid;
  }

  .input-set {
    position: absolute;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 0;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;

    img {
      width: 16px;
      height: 16px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

.borderNoneSelect {
  border: none;
  text-align: center;

  &:focus-visible {
    border: none;
    outline: none;
  }
}

.colors {
  color: black;
}

// image modal size ------------
.image-large-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 9;
  justify-content: center;
  align-items: center;

  .close-icon {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 20px;
    cursor: pointer;
  }

  .image-zoom {
    width: 90%;
    // height: 500px;
    object-fit: contain;
  }
}

.cursorA {
  cursor: pointer;
}

.sticky-card {
  position: sticky;
  top: 130px;
}

.fontfan {
  font-family: "Gilroy";
  font-weight: 600;
  letter-spacing: 2px;
}


.status_on_hold {
  border: 0 !important;
  border-radius: 50px;
  box-shadow: none !important;
  // cursor: pointer;
  display: inline-block !important;
  height: 28px;
  line-height: 28px;
  margin-top: 6px;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #FFF;
  background-color: #F5A623;
  text-decoration: none !important;
}


.status_pending {
  border: 0 !important;
  border-radius: 50px;
  box-shadow: none !important;
  // cursor: pointer;
  display: inline-block !important;
  height: 28px;
  line-height: 28px;
  margin-top: 6px;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  background-color: #D4D4D4;
  text-decoration: none !important;
}


.status_approved {
  border: 0 !important;
  border-radius: 50px;
  box-shadow: none !important;
  // cursor: pointer;
  display: inline-block !important;
  height: 28px;
  line-height: 28px;
  margin-top: 6px;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #FFF;
  background-color: #01BD6F;
  text-decoration: none !important;
}


.status_declined {
  border: 0 !important;
  border-radius: 50px;
  box-shadow: none !important;
  // cursor: pointer;
  display: inline-block !important;
  height: 28px;
  line-height: 28px;
  margin-top: 6px;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #FFF;
  background-color: #D53049;
  text-decoration: none !important;
}


.status_order_complete {
  border: 0 !important;
  border-radius: 50px;
  box-shadow: none !important;
  // cursor: pointer;
  display: inline-block !important;
  height: 28px;
  line-height: 28px;
  margin-top: 6px;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #FFF;
  background-color: #535172;
  text-decoration: none !important;
}


.status_submitted {
  border: 0 !important;
  border-radius: 50px;
  box-shadow: none !important;
  // cursor: pointer;
  display: inline-block !important;
  height: 28px;
  line-height: 28px;
  margin-top: 6px;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #FFF;
  background-color: #5E74E6;
  text-decoration: none !important;
}




.status_approved_for_production {
  border: 0 !important;
  border-radius: 50px;
  box-shadow: none !important;
  // cursor: pointer;
  display: inline-block !important;
  height: 28px;
  line-height: 28px;
  margin-top: 6px;
  // max-width: 100%;
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #FFF;
  background-color: #5E74E6;
  text-decoration: none !important;
}

@keyframes hello-dialog {
  from {
    opacity: 0;
  }

  to {
    opactity: 1;
  }
}

.textColor {
  color: #000;
}

#ct-container {
  top: 101px !important;
}

.info-tooltip {
  margin-left: 10px;
  position: relative;

  &:hover {
    p {
      display: block;
    }
  }

  img {
    width: 20px;
    height: 16px;
    cursor: pointer;
    margin-top: -4px;
  }

  p {
    text-align: center;
    background-color: $white;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    top: 25px;
    right: 0;
    width: 208px;
    height: auto;
    font-size: 12px;
    display: none;
    border: 1px solid #ddd;
  }
}

.error-text {
  p {
    font-weight: 500;
    font-size: 16px;
    color: red;
    line-height: 22px;
    margin: 0px;
  }
}